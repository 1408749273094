/* RADAR */
.pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fcff31;
  -moz-animation: pulsating 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: pulsating 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
  z-index: 5;
}

.ringbase {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  z-index: 10;
}

.ring0 {
  box-shadow: 0 0 2px 1px #2dff2d, inset 0 0 1px 1px #2dff2d;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
}

.ring1 {
  box-shadow: 0 0 2px 1px #2dff2d, inset 0 0 2px 1px #2dff2d;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

.ring2 {
  box-shadow: 0 0 1px 0px #2dff2d, inset 0 0 1px 0px #2dff2d;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 0.5s;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.5s;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

@-webkit-keyframes pulsating {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes pulsating {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulsating {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: scale(0.4, 0.4);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: scale(0.4, 0.4);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    -moz-transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

@keyframes ring {
  0% {
    transform: scale(0.4, 0.4);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

.pointer {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-animation: circling 2s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: circling 2s linear;
  -moz-animation-iteration-count: infinite;
  animation: circling 2s linear;
  animation-iteration-count: infinite;
  z-index: 6;
}

.pointer div {
  width: 49%;
  border-bottom: 2px solid #2dff2d;
}

.dot {
  /* opacity: 1; */
  background-color: #fa4b4b;
  border: 0.2rem solid #fa4b4b;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: blink 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: blink 2s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: blink 2s ease-out;
  animation-iteration-count: infinite;
  z-index: 10;
}

.dot.pos1 {
  left: 10%;
  /* top: 38px; */
  top: 35%;
}

.dot.pos2 {
  left: 40%;
  top: 20%;
  /* top: 1vw; */

  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes circling {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes circling {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes circling {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
