/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 27, 2021 */



@font-face {
    font-family: 'lcd_solidregular';
    src: url('./lcdsolid-webfont.woff2') format('woff2'),
         url('./lcdsolid-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}