/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
/* @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro"); */
/*link: https://fonts.google.com/specimen/IBM+Plex+Mono?preview.text=asdfasdfasdf&preview.text_type=custom&sidebar.open=true&selection.family=Roboto */
@import "./font/stylesheet.css";

.main {
  position: relative;
  width: 100vw;
  height: auto;
  /* margin: auto; */
  color: transparent;
  z-index: 0;
  overflow: hidden;
}

a {
  text-decoration: none;
}
.Project-container {
  vertical-align: top;
  display: flex;
  flex-wrap: wrap;
  padding: auto;
  justify-content: center;
  width: 100%;
}
.Project-item {
  width: 150px;
  padding: 10px;
}

.background {
  display: flex;
  text-align: center;
  justify-content: center;
  background-image: linear-gradient(black, #000014);
  width: 100vw;
  /* height: 20000vh; */
  position: relative;
  z-index: 0;
  /* height: fit-content; */
}

.tiltdiv {
  /* z-index: 1; */
  position: relative;
}

.tiltimg {
  width: 100vw;
  height: auto;
}

.tiltframe {
  width: 100vw;
  height: auto;
  overflow: hidden;
}
.headshotimg {
  width: 100%;
  border-radius: 50%;
}
/* NAME TYPING */
.name {
  height: 45px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  /* font-family: "Source Code Pro", monospace; */
  font-family: "lcd_solidregular", sans-serif;
  font-size: 35px;
  /* font-weight: 600; */
  color: rgba(0, 255, 0, 0.75);
  position: absolute;
  top: 7%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
  /* z-index: 1; */
  letter-spacing: 1px;
}

.border {
  /* Thickness of cursor */
  border-bottom: solid 5px rgba(0, 255, 0, 0.75);
  position: absolute;
  right: -2px;
  /* Width of cursor */
  width: 26px;
  animation: animated-cursor 600ms steps(11, end) infinite;
}
/* Animation */
.name {
  animation: animated-text 1500ms steps(11, end) 0.5s infinite alternate both;
}

/* text animation */
@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    /* width of entire text */
    width: 246px;
  }
}
/* cursor animations */
@keyframes animated-cursor {
  from {
    border-bottom-color: rgba(0, 255, 0, 0.75);
  }
  to {
    border-bottom-color: transparent;
  }
}

.bodybackground {
  height: 100%;
  width: 100vw;
  background-image: linear-gradient(#000001, #61a0ff);
  justify-content: center;
  text-align: center;
  display: flex;
  position: relative;
}

.windowimage {
  position: fixed;
  z-index: 1;
  left: 36%;
  top: 5.3vh;
  width: 30%;
}

.cloudimg {
  width: 60vw;
  height: auto;
  overflow: visible;
}

.footer {
  z-index: 5;
  height: 100px;
  width: 100vw;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: "lcd_solidregular", sans-serif;
  padding-bottom: 30px;
}

.footertextdiv {
  z-index: 5;
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
  font-size: 1.1rem;
  color: rgb(49, 39, 39);
}
