@keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(50vw, 0px, 0px);
  }
}
@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(50vw, 0px, 0px);
  }
}

@-moz-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(50vw, 0px, 0px);
  }
}

@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(50vw, 0px, 0px);
  }
}
//background2
@keyframes move-background2 {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-50vw, 0px, 0px);
  }
}
@-webkit-keyframes move-background2 {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-50vw, 0px, 0px);
  }
}

@-moz-keyframes move-background2 {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-50vw, 0px, 0px);
  }
}

@-webkit-keyframes move-background2 {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-50vw, 0px, 0px);
  }
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.stars {
  // background: black
  //   url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  background: #000001 url(./stars.png) repeat;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 0;
}

.twinkling {
  width: 50vw;
  height: 100%;
  // background: transparent
  //   url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png")
  //   repeat;
  background: transparent url(./twinkling.png) repeat;
  background-size: 100vh 50vw;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  animation: move-background 100s linear infinite;

  -moz-animation: move-background 100s linear infinite;
  -ms-animation: move-background 100s linear infinite;
  -o-animation: move-background 100s linear infinite;
  -webkit-animation: move-background 100s linear infinite;
}

.twinkling2 {
  width: 50vw;
  height: 100%;
  // background: transparent
  //   url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png")
  //   repeat;
  background: transparent url(./twinkling.png) repeat;
  background-size: 100vh 50vw;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  animation: move-background2 100s linear infinite;

  -moz-animation: move-background2 100s linear infinite;
  -ms-animation: move-background2 100s linear infinite;
  -o-animation: move-background2 100s linear infinite;
  -webkit-animation: move-background2 100s linear infinite;
}

// .clouds{
// 	width:10000px;
// 	height: 100%;
// 	background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/clouds_repeat.png") repeat;
// 	background-size: 1000px 1000px;
//     position: absolute;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 3;

//    -moz-animation:move-background 150s linear infinite;
//   -ms-animation:move-background 150s linear infinite;
//   -o-animation:move-background 150s linear infinite;
//   -webkit-animation:move-background 150s linear infinite;
//   animation:move-background 150s linear infinite;
// }
.moonpng {
  height: auto;
  width: 30vw;
  position: absolute;
  z-index: 3;
  right: 10vw;
  top: 50vh;
  // background: black url(./moon.png) repeat;
}

.marspng {
  height: auto;
  width: 5vw;
  position: absolute;
  z-index: 3;
  left: 13vw;
  top: 20vh;
  opacity: 0.7;
}

.spacestationpng {
  height: auto;
  width: 25vw;
  position: absolute;
  z-index: 3;
  left: 100vw;
  top: 80vh;
  animation: orbit2 350s linear infinite;

  opacity: 0.8;
}

@keyframes orbit2 {
  from {
    transform: translate(-100vw, -200px);
  }
  to {
  }
}
